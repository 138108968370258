import React from 'react';
import PropTypes from 'prop-types';

import { Toolbar, withStyles } from '@material-ui/core';
import { get, isEqual } from 'lodash';
import { fofNavigationBreakpoint, siteMaintenanceMode } from '../../../../constants';

import HamburgerMenu from '../../../navigation/HumburgerMenu';
import TopNavigation from '../../../navigation/TopNavigation';
import Logo from '../../../navigation/Logo';
import AppBar from '../Toolbar';
import MobileNavigation from '../../../navigation/MobileNavigation';
import QuickSearch from '../../../search/QuickSearch';
import QuickSearchIconButton from '../../../search/QuickSearchIconButton';
import Subscription from '../../../navigation/Subscription';
import MemoryListIconButton from '../../../memorylist/MemoryListIconButton';
import MemoryListDialog from '../../../memorylist/MemoryListDialog';
import LanguageSwitcher from '../../../navigation/LanguageSwitcher';
import RichText from '../../../shared/RichText';
import Visible from '../../../shared/Visible';
import { useWindowSize } from '../../../hooks/useWindowSize';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.header.background,
    '@media print': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  navbar: {
    backgroundColor: theme.palette.header.background,
    zIndex: '1',

    justifyContent: 'space-between',
    height: '60px',
    boxShadow: '0 0 60px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: { minHeight: '80px' },
    '@media print': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  logo: {
    marginRight: '40px',
    [theme.breakpoints.up('md')]: { marginRight: '110px' },
  },
  navigation: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      display: 'inline',
    },
    '@media print': {
      display: 'none !important',
    },
  },
  mobileNavigation: {
    flexGrow: 1,
    zIndex: '1',
    position: 'relative',

    display: 'inline',
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      display: 'none',
    },
    '@media print': {
      display: 'none !important',
    },
  },
  rightSide: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {},
    '@media print': {
      display: 'none !important',
    },
  },
  subscriptionIcon: {
    display: 'none',
    [theme.breakpoints.up('650')]: {
      display: 'inline',
    },
  },
  b2bLink: {
    display: 'none',
    [theme.breakpoints.up('500')]: {
      display: 'inline',
    },
  },
  quickSearchIcon: {
    display: 'none',
    [theme.breakpoints.up('500')]: {
      display: 'flex',
    },
  },
  hamburgerMenu: {
    padding: '10px',
    marginLeft: '10px',
    display: 'inline',
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      display: 'none',
    },
  },
  icon: { fontSize: '2rem' },
});

function Header({ classes, pageData }) {
  const header = get(pageData, 'mainTheme.header');

  if (header) {
    const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
    const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);
    const headerElements = get(header, 'headerElements');
    const showLogo = !!headerElements.includes('Show logo');
    const showSearch = !!headerElements.includes('Show search');
    const showNavigation = !!headerElements.includes('Show navigation');
    const showNewsletterSubscription = !!headerElements.includes('Show newsletter subscription');
    const headerContent = get(header, 'headerContent');
    const { width } = useWindowSize();
    const isMobile = width <= 600;

    return (
      <div>
        <AppBar className={classes.root} pageData={pageData}>
          <Toolbar className={classes.navbar}>
            {showLogo && <Logo pageData={pageData} className={classes.logo} />}
            {showNavigation && (
              <div className={classes.navigation}>
                {!isMaintenanceModeActive && <TopNavigation pageData={pageData} />}
              </div>
            )}
            <div className={classes.rightSide}>
              <LanguageSwitcher className={classes.languageSwitcher} pageData={pageData} />
              {!isMaintenanceModeActive && showNewsletterSubscription && (
                <Subscription className={classes.subscriptionIcon} />
              )}
              <Visible hidden={isMobile}>
                <QuickSearchIconButton className={classes.quickSearchIcon} />
              </Visible>

              <MemoryListIconButton />

              {headerContent && <RichText pageData={pageData} textData={headerContent} className={classes.content} />}

              {showNavigation && <HamburgerMenu className={classes.hamburgerMenu} />}
            </div>
          </Toolbar>
          {!isMaintenanceModeActive && (
            <div className={classes.mobileNavigation}>
              <TopNavigation pageData={pageData} />
            </div>
          )}
          <Visible visible={!isMaintenanceModeActive && showSearch}>
            <QuickSearch pageData={pageData} />
          </Visible>
        </AppBar>
        {showNavigation && <MobileNavigation pageData={pageData} />}

        <MemoryListDialog pageData={pageData} />
      </div>
    );
  }

  return null;
}

Header.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Header.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(Header);
